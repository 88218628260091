import { Badge } from "antd";

export const useCommonDeviceInfo = (device) => {
    let status = { icon: "warning", text: "Неизвестный" };
    switch (device.status) {
        case "online":
            status.icon = "success";
            status.text = "Онлайн";
            break;
        case "offline":
            status.icon = "error";
            status.text = "Оффлайн";
            break;
        default:
            break;
    }

    return [
        // {
        //     key: "1",
        //     label: "id",
        //     children: <span>{device.id}</span>,
        // },
        {
            key: "2",
            label: "Имя устройства (номер)",
            children: <span>{device.name}</span>,
        },
        {
            key: "3",
            label: "Объем топливного бака",
            children: <span>{device.fuelTank || '-'}</span>,
        },
        {
            key: "5",
            label: "Состояние автомобиля",
            children: (
                <span>{device.motionstate ? "В движении" : "Остановлен"}</span>
            ),
        },
        {
            key: "4",
            label: "Статус",
            children: <Badge status={status.icon} text={status.text} />,
        },
        // {
        //     key: "6",
        //     label: "",
        //     children: "",
        // },
    ];
}
