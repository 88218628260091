import dayjs from "dayjs";
import "./App.css";
import { DataLayout } from "./components/DataLayout";
import { Spin } from 'antd';
import React, { useEffect, useState } from "react";
import axios from "axios";

export const tempUser = "iSpirit";
export const urlBackend = "https://ditstop.ru:44301";
// export const urlBackend = "http://127.0.0.1:8080";

export const App = () => {
  const [userData, setUserData] = useState(null);
  const [datesRange, setDates] = useState({ start: dayjs(new Date()).format('YYYY-MM-DD'), end: dayjs(new Date()).format('YYYY-MM-DD') })

  const getUserData = async () => {
    await axios.get(`${urlBackend}/user/${tempUser}/devices/list`)
      .then((e) => setUserData(() => ({ ...e.data })))
      .catch((e) => console.log(e));
    console.log('CONNNECTION');
  }

  useEffect(() => {
    getUserData()
  }, []);

  useEffect(() => {
    if (userData) {
      const interval = setInterval(() => {
        getUserData()
      }, [300000])
      return () => {
        clearInterval(interval)
      }
    }
  }, [userData]);


  return (
    <div className="App">
      {userData ? (
        <DataLayout userData={userData} datesRange={datesRange} setDates={setDates} />
      ) : (
        <div className="spinner">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};
