import { Badge } from "antd";

export const useAdditionalInfo = (device) => {
  let status = { fuelBegin: "warning", fuelEnd: "Неизвестный" };
  // id  "deviceId": 32,
  // название "deviceName": "787УМ",
  // начальное состояние топлива  "fuelBegin": 14,
  // конечное состояние топлива  "fuelEnd": 35,
  // потрачено топлива за период  "fuelSpent": 1015,
  // заправлено топлива за период  "fuelCharged": 1036,
  // средний расход  "avgFuelCons": 14.99,
  // пробег за период  "mileage": 6771.0,

  return [
    {
      key: "1",
      label: "Начальное состояние топлива",
      children: <span>{device.fuelBegin || '-'}</span>,
    },
    {
      key: "2",
      label: "Конечное состояние топлива",
      children: <span>{device.fuelEnd || '-'}</span>,
    },
    {
      key: "3",
      label: "Потрачено топлива за период",
      children: <span>{device.fuelSpent || '-'}</span>,
    },
    {
      key: "4",
      label: "Заправлено топлива за период",
      children: <span>{device.fuelCharged || '-'}</span>,
    },
    {
      key: "5",
      label: "Средний расход",
      children: <span>{device.avgFuelCons || '-'}</span>,
    },
    {
      key: "6",
      label: "Пробег за период",
      children: <span>{device.mileage || '-'}</span>,
    },
  ];
};
