import React, { useMemo, useState } from "react";
import { PoweroffOutlined } from "@ant-design/icons";
import {
    Layout,
    Menu,
    theme,
    Descriptions,
    DatePicker,
    Button,
    Space,
    Input,
    ConfigProvider
} from "antd";
import dayjs from "dayjs";
import axios from "axios";
import locale from 'antd/locale/ru_RU';
import 'dayjs/locale/ru';
import updateLocale from 'dayjs/plugin/updateLocale';

import "./styles.css";
import { useCommonDeviceInfo } from "./useCommonDeviceInfo";
import { useAdditionalInfo } from "./useAdditonalInfo";
import { urlBackend } from "../App";


dayjs.locale('ru');
dayjs.extend(updateLocale);
dayjs.updateLocale('ru', {
    weekStart: 1,
});


const { Header, Content, Sider } = Layout;

export const DataLayout = ({ userData, datesRange, setDates }) => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const [device, setDevice] = useState(userData.devices[0]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);

    const devicesMemo = useMemo(() => {
        const filteredDevices = search
            ? userData.devices.filter((e) =>
                e.name.toLowerCase().includes(search.toLowerCase())
            )
            : userData.devices;
        return filteredDevices.map((e, index) => ({
            key: String(index + 1),
            icon: React.createElement(PoweroffOutlined),
            label: e.name,
            danger: e.status !== "online",
            id: e.id,
            status: e.status,
            name: e.name,
            fuelTank: e.fuelTank
        }));
    }, [userData, search]);

    const deviceInfo = useCommonDeviceInfo(device);
    const deviceAdditionalInfo = useAdditionalInfo(device);

    const onChangeDateEnd = (date, dateString) => {
        setDates((s) => ({ ...s, end: dateString }));
    };

    const onChangeDateStart = (date, dateString) => {
        setDates((s) => ({ ...s, start: dateString }));
    };

    const selectedKeys = useMemo(() => {
        return devicesMemo.findIndex((e) => e.id === device.id);
    }, [device.id, devicesMemo]);

    const onSubmitDates = () => {
        setLoading(true);
        axios
            .get(
                `${urlBackend}/device/${device.id}/consumption/for/${datesRange.start}/to/${datesRange.end}`
            )
            .then((e) => (e.data ? setDevice((d) => ({ ...d, ...e.data })) : ""))
            .catch((e) => console.log({ e }))
            .finally(() => setLoading(false));
    }

    return (
        <Layout hasSider>
            <Sider
                className="sider"
                width={300}
                style={{
                    overflow: "auto",
                    height: "100vh",
                    position: "fixed",
                    left: 0,
                    top: 0,
                    bottom: 0,
                }}
            >
                <div className="search">
                    <Input
                        disabled={loading}
                        placeholder="Имя авто"
                        onPressEnter={(e) => setSearch(e.target.value)}
                        onChange={(e) => setSearch(e.target.value)}
                        allowClear
                    />
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    items={devicesMemo}
                    selectedKeys={[`${selectedKeys + 1}`]}
                    onSelect={(e) => setDevice(devicesMemo[Number(e.key) - 1])}
                    disabled={loading}
                    className="menu"
                />
            </Sider>
            <Layout style={{ marginLeft: 300 }}>
                {/*<Header style={{ padding: 0, background: colorBgContainer }} />*/}
                <Space direction="vertical" size={12}></Space>
                <Content
                    style={{
                        margin: "0px 16px 0",
                        overflow: "initial",
                        padding: 24,
                        textAlign: "center",
                        height: "100%",
                    }}
                >
                    <Descriptions
                        title="Информация о машине"
                        bordered
                        column={2}
                        items={deviceInfo}
                    />
                    <div className="date-control">
                        <div className="dates">
                            <span>Период отчёта с</span>
                            <ConfigProvider locale={locale}>
                                <DatePicker
                                    allowClear={false}
                                    value={dayjs(datesRange.start)}
                                    onChange={onChangeDateStart}
                                />
                            </ConfigProvider>
                        </div>
                        <div className="dates">
                            <span>по</span>
                            <ConfigProvider locale={locale}>
                                <DatePicker
                                    allowClear={false}
                                    value={dayjs(datesRange.end)}
                                    onChange={onChangeDateEnd}
                                />
                            </ConfigProvider>

                        </div>
                        <Button disabled={loading} onClick={onSubmitDates} type="primary">
                            Получить отчет
                        </Button>
                    </div>
                    <div>
                        <Descriptions
                            title="Информация за период"
                            bordered
                            column={2}
                            items={deviceAdditionalInfo}
                        />
                    </div>
                    <div className="charges-wrapper">
                        {device.charges?.length ?
                            device.charges.map((e, i) => (
                                <div className="charges" key={i}>
                                    <span>
                                        Дата: {new Date(e.chargeDateBegin).toLocaleDateString("Ru-ru", {year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric", weekday: "short"} )}
                                    </span>
                                    <span>Заправлено: {e.fuel} л.</span>
                                </div>
                            )) :
                            <div>Нет информации по заправкам</div>}
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};
